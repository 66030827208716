// Imports for external libraries go here.
import React, { FC } from 'react';
import clsx from 'clsx';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { Button, RichText } from '@marriott/mi-ui-library';
import { CardOverviewHorizontalProps } from './CardOverviewHorizontal.types';
import { StyledCardOverview } from './CardOverviewHorizontal.styles';
import { CobrandImage } from '../CobrandImage/CobrandImage';
export const CardOverviewHorizontalConfig = {
  emptyLabel: 'CardOverviewHorizontal',
  isEmpty: () => true,
  resourceType: `/components/content/CardOverviewHorizontal`,
};

export const CardOverviewHorizontal: FC<CardOverviewHorizontalProps> = (props: CardOverviewHorizontalProps) => {
  const {
    cardType,
    imagePath,
    altText,
    header,
    pointSectionSeparator,
    pointSectionDescription,
    cardSectionSeparator,
    cardSectionDescription,
    linkText,
    linkUrl,
    openInaNewTab,
    additionalTrackingVariables,
    trackingDescription,
    impressionTrack,
    trackingContentPosition,
    atCCeVar48,
    clickTrack,
    merchandisingCategory,
    impressionCount,
    trackingTag,
    trackingOfferType,
    componentId,
    appliedCssClassNames,
    customClass,
    styleclass,
  } = props;

  const trackingProperties = {
    trackingContentPosition,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    merchandisingCategory,
    atCCeVar48,
    additionalTrackingVariables,
    clickTrack: clickTrack === 'true',
    impressionTrack: impressionTrack === 'true',
    impressionCount: impressionCount === 'true',
    isCoBrand: true,
  };

  const linkType = openInaNewTab === 'true' ? 'external' : 'internal';
  const clickTrackValue = `${trackingContentPosition}|${trackingDescription}|${linkType}|${additionalTrackingVariables}`;
  return (
    <StyledCardOverview
      data-component-name="m-cobrand-CardOverviewHorizontal"
      id={componentId}
      className={clsx(
        'card-overview d-flex justify-content-center flex-grow-1',
        `${appliedCssClassNames ? appliedCssClassNames : ''}`
      )}
      data-cardtype={cardType}
    >
      <div
        className={clsx(
          'card-overview-bordered t-background-color',
          'd-flex flex-column flex-lg-row justify-content-between flex-grow-1',
          'px-4 pt-4 pb-3 px-md-5 py-md-4',
          customClass,
          styleclass
        )}
      >
        <CobrandImage
          defaultImageURL={imagePath}
          title=""
          customClass="card-overview__image mr-lg-5 ml-lg-2"
          altText={altText}
        ></CobrandImage>
        <div className={clsx('d-flex flex-column flex-grow-1', 'mt-2 mt-lg-0 mr-2')}>
          {header && header.length && (
            <RichText componentId={`header-${componentId}`} text={header} customClass={clsx('card-overview-header')} />
          )}
          {pointSectionSeparator === 'true' && <hr className={clsx('mb-2')} />}
          {pointSectionDescription && pointSectionDescription.length && (
            <RichText
              componentId={`pointsSection-${componentId}`}
              text={pointSectionDescription}
              customClass={clsx('card-overview-subheader mt-2 mt-md-1 mb-2 mb-md-0')}
            />
          )}
          {cardSectionSeparator === 'true' && <hr className={clsx('mt-0 mt-lg-1')} />}
          {cardSectionDescription && cardSectionDescription.length && (
            <RichText
              componentId={`cardSection-${componentId}`}
              text={cardSectionDescription}
              customClass={clsx(
                'card-overview-subheader card-overview-richtext d-flex flex-column flex-grow-1 mt-md-2 mt-lg-1'
              )}
            />
          )}
          <div>
            <Button
              isLink={true}
              href={linkUrl}
              target={openInaNewTab === 'true' ? '_blank' : '_self'}
              /* "track-completed" class is added to escape getNonCoBrandTrackValue method in clientlib.js */
              className={clsx('m-button-m mt-2 mt-md-3 mt-lg-2 mb-0 px-3', 'track-completed')}
              trackingProperties={trackingProperties}
              buttonCopy={linkText}
              linkType={linkType}
              custom_click_track_value={clickTrackValue}
              linkAriaLabelOpenNewWindow={openInaNewTab === 'true' ? 'Open in new tab' : ''}
            ></Button>
          </div>
        </div>
      </div>
    </StyledCardOverview>
  );
};
