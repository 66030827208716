import { Button } from '@marriott/mi-ui-library';
import { useRef } from 'react';
import clsx from 'clsx';
import { canUseDOM, getEVAR48, getSPID } from '@marriott/mi-headless-utils';
import {
  ButtonStyle,
  IMerchandisingSmallBannerButton,
  ITargetDataProps,
  TrackingPropsInterface,
} from '../organisms/MerchandisingSmallBanner/MerchandisingSmallBanner.types';

export const MerchandisingSmallBannerButton = (props: IMerchandisingSmallBannerButton) => {
  const {
    ctaType,
    isAuth,
    openInNewTab,
    destinationUrl1,
    callToActionText1,
    openInNewWindowAriaLabel,
    isVariationsAvailable,
    targetData,
    metaData,
    trackingProperties,
    isCobrand,
    isTargetContentError,
    mboxParameter,
  } = props;

  const ref = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;

  const getButtonStyle = (ctaType: string, openInNewTab: boolean): string => {
    switch (ctaType) {
      case 'primaryButton':
        return openInNewTab ? ButtonStyle.PRIMARY_BUTTON_EXTERNAL : ButtonStyle.PRIMARY_BUTTON;
      case 'secondaryButton':
        return openInNewTab ? ButtonStyle.SECONDARY_BUTTON_EXTERNAL : ButtonStyle.SECONDARY_BUTTON;
      default:
        return '';
    }
  };

  /**
   *
   * @returns it returns the value of atCCeVar48
   * @description this fucntion returns value of atCCeVar48, conditionally,
   *
   */
  const getAtCCevar48 = () => {
    if (canUseDOM && isCobrand) {
      // If type in MBOP response is cobrand then construct evar48
      if (isTargetContentError || !mboxParameter) return trackingProperties?.trackingDescription;
      else return getEVAR48(targetData as ITargetDataProps);
    }
    // if type in MBOP response is non cobrand then pass empty in evar48
    return '';
  };
  const buttonTypeClasses = getButtonStyle(ctaType, openInNewTab);

  const trackingPropertiesDynamic: TrackingPropsInterface = {
    trackingContentPosition: metaData
      ? props?.mboxParameter?.replace(/-mbox$/, '')
      : props?.trackingProperties?.trackingContentPosition,
    trackingDescription: metaData ? 'dca' : props?.trackingProperties?.trackingDescription,
    trackingOfferType: metaData ? 'dca' : props?.trackingProperties?.trackingOfferType,
    trackingTag: metaData ? metaData?.merTag : props?.trackingProperties?.trackingTag,
    additionalTrackingVariables: metaData
      ? metaData?.optionalFields
        ? 'true'
        : 'false'
      : props?.trackingProperties?.atCCeVar48,
    atCCeVar48: getAtCCevar48(),
    clickTrack: props?.trackingProperties?.isClickTrack ?? true,
    impressionTrack: props?.trackingProperties?.impressionTrack ?? true,
    impressionCount: props?.trackingProperties?.impressionCount ?? true,
    isAuth: isAuth,
    clickTrackValSeparator: ';',
    merchandisingCategory: metaData ? metaData?.type : props?.trackingProperties?.merchandisingCategory,
    isDCAEnabled: true,
    // only pick when we have UXL response
    spid: getSPID({ metaData: metaData }),
    rpcCode: metaData?.rpcCode || '',
    cell: metaData?.cell || '',
    offerCode: metaData?.offerCode || '',
    type: metaData?.type || '',
  };

  return (
    <Button
      className={clsx(
        'merchandising-small-banner__button',
        `${buttonTypeClasses} m-button-s text-center align-self-end pt-2`,
        {
          'mt-4 mt-md-0': !isVariationsAvailable,
        }
      )}
      href={destinationUrl1}
      isLink={true}
      target={openInNewTab === true ? '_blank' : '_self'}
      trackingProperties={trackingPropertiesDynamic}
      rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
      ref={ref}
      buttonCopy={callToActionText1}
      linkAriaLabelOpenNewWindow={openInNewWindowAriaLabel}
      compName={trackingProperties?.trackingContentPosition}
      data-is-click-track={trackingProperties?.isClickTrack}
    />
  );
};
