/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
import { canUseDOM } from '@marriott/mi-ui-library';
import { Breakpoint, DeviceSize, selectorList } from '../../constants/constants';
import { RefObject } from 'react';
import { FeatureIcon, FeatureIconColor, iconColorMap } from './enums';

//Method to return column class helper for various components
export const getColumnClass = (columnLayout: string, tabType: string) => {
  let customClass = '';
  if (columnLayout === '1-column') {
    customClass = 'col-12 ';
  } else if (columnLayout === '3-column') {
    if (tabType === 'personal') {
      customClass = 'col-6 col-md-4';
    } else {
      customClass = 'col-12 col-md-4';
    }
  } else {
    customClass = 'col-6';
  }
  return customClass;
};

export function getDataLayerScript(dataLayer: { data: any; mvpOffersData: string }) {
  return `var dataLayer = ${dataLayer?.data ? JSON.stringify(dataLayer?.data[0]) : '{}'}; var mvpOffers = ${
    dataLayer?.mvpOffersData && dataLayer?.mvpOffersData !== 'null' ? dataLayer?.mvpOffersData : '{}'
  };`;
}

//Method to remove extra spacing in on resize
export const removeExtraHeights = (ref: RefObject<HTMLDivElement>) => {
  if (canUseDOM) {
    selectorList?.map(({ selector, rowCount }) => {
      for (let row = 1; row <= rowCount; row++) {
        const requiredElem = ref?.current?.querySelectorAll(`.${selector}-${row}`);
        if (requiredElem && requiredElem.length) {
          requiredElem.forEach((node: any) => {
            if (node && node.style) {
              node.style.minHeight = '';
            }
          });
        }
      }
    });
  }
};

//Method to equalize height of points section elements
const equalizeCardHeight = (selector: string, rowCount: number, ref: RefObject<HTMLDivElement>) => {
  if (canUseDOM) {
    for (let row = 1; row <= rowCount; row++) {
      const allNodes = ref?.current?.querySelectorAll(`.${selector}-${row}`);
      let maxHeight = 0;
      if (allNodes && allNodes.length) {
        allNodes.forEach(node => {
          if (node.clientHeight > maxHeight) {
            maxHeight = node.clientHeight;
          }
        });
        allNodes.forEach((node: any) => {
          if (node && node.style) {
            node.style.minHeight = maxHeight + 'px';
          }
        });
      }
    }
  }
};

export const equalizeBankLandingCards = (ref: RefObject<HTMLDivElement>) => {
  selectorList?.map(currentElem => {
    const { selector, rowCount } = currentElem;
    equalizeCardHeight(selector, rowCount, ref);
  });
};

export const referAFriend = (dataLayer: any, ref: RefObject<HTMLDivElement>) => {
  if (
    dataLayer &&
    dataLayer['mr_prof_authentication_state'] &&
    dataLayer['mr_prof_authentication_state'] === 'authenticated'
  ) {
    const cardDetails = dataLayer['mr_prof_cc_all'];
    const detailsArray = cardDetails ? cardDetails?.toString()?.split(',') : [];
    const ccFromDom: (string | undefined)[] = [];
    const cardDetailsElements = ref?.current?.querySelectorAll('.refer-friend-link');
    if (cardDetailsElements) {
      cardDetailsElements.forEach(ele => {
        const tempEle = ele as HTMLElement;
        ccFromDom.push(tempEle.dataset?.['creditcardcode']?.trim());
      });
      const tempArray = ccFromDom.filter(code => detailsArray.includes(code as string));
      tempArray.forEach(element => {
        cardDetailsElements.forEach(ele => {
          const tempEle = ele as HTMLElement;
          const cardCodeforEle = tempEle.dataset?.['creditcardcode']?.trim();
          if (cardCodeforEle === element) {
            tempEle.classList.remove('d-none');
          }
        });
      });
    }
  }
};
export const getUserLocation = function (adobeDataLayer: unknown = undefined) {
  const dataLayer = (adobeDataLayer || global?.window?.dataLayer) as Record<string, string | unknown>;
  if (dataLayer?.['memState'] && dataLayer?.['memState'] === 'authenticated') {
    return dataLayer?.['mr_prof_address_country'];
  } else {
    return dataLayer?.['browser_akamai_loc_country'];
  }
};
export const setObserver = (componentId: any, ref: RefObject<HTMLDivElement>) => {
  const pageWcmMode = document.getElementsByTagName('html')[0];
  const updateStyleComponent = ref?.current?.querySelector(`#${componentId}__slides`);
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.attributeName === 'class') {
        const currWcmMode = pageWcmMode?.getAttribute('class');
        if (currWcmMode?.includes('Edit')) {
          updateStyleComponent?.classList.add('glide__slides_authoring');
        } else if (currWcmMode?.includes('Preview')) {
          updateStyleComponent?.classList.remove('glide__slides_authoring');
        }
      }
    });
  });
  observer.observe(pageWcmMode, { attributes: true });

  return () => {
    observer.disconnect();
  };
};

export const shouldCardDisplay = (cardType: string, activeTab: string) => {
  let displayClass = true;
  if (cardType && cardType === 'business') {
    if (activeTab && activeTab !== 'business') {
      displayClass = false;
    }
  }
  if (cardType && cardType === 'personal') {
    if (activeTab && activeTab !== 'personal') {
      displayClass = false;
    }
  }
  return displayClass;
};

// helper function to get icon color based on status
export const getIconColor = (status: FeatureIcon): FeatureIconColor => {
  return iconColorMap[status];
};

/**
 * Function to determine if the user is on a mobile device
 */
export const isMobile = (): boolean => {
  // handle server-side rendering case
  if (typeof navigator === 'undefined') {
    return false;
  }
  const isTablet = !!(
    (/Macintosh/i.test(navigator.userAgent) || /Linux/i.test(navigator.userAgent)) &&
    navigator?.maxTouchPoints > 1
  );
  return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || isTablet;
};

/**
 * Function extracts a paragraph that includes "Annual Fee" in <p></p> tag from the authored html code
 */
export const extractAnnualFee = (innerHtml: string): string | undefined => {
  const regex = /<p[^>]*>(.*?Annual Fee.*?)<\/p>/;
  const match = innerHtml.match(regex);
  return match?.[0];
};

export const setCardArray = (cardArray: any) => {
  const dataArray: any[] = [];
  for (const cardKey in cardArray) {
    if (cardKey !== 'categories') {
      const eachCardData = Object.values(cardArray[cardKey]);
      eachCardData.map((data: any) => {
        data.cardId = cardKey;
        return data;
      });
      dataArray.push(...eachCardData);
    }
  }
  return dataArray;
};

// This function returns the image width based on the provided breakpoint ('lg', 'md', 'sm', 'all').
export const getContentBlockImageDimensions = (breakpoint: 'lg' | 'md' | 'sm' | 'all') => {
  switch (breakpoint) {
    case DeviceSize?.Large:
      return 433;
    case DeviceSize?.Medium:
      return 345;
    case DeviceSize?.Small:
      return 315;
    default:
      return 315;
  }
};

// This function generates image renditions based on the provided source and a list of image sizes.
export const generateImageRenditions = (
  src: string,
  imageSizeList: { imageWidth?: number; imageHeight?: number; breakpoint: string; queryType: string }[]
) => {
  return imageSizeList.map(rendition => {
    const width = rendition?.imageWidth ? `${rendition.imageWidth * 2}px` : '*';
    const height = rendition?.imageHeight ? `${rendition.imageHeight * 2}px` : '*';
    const renditionPath = `${src}?output-quality=70&interpolation=progressive-bilinear&downsize=${width}:${height}`;
    return {
      renditionPath,
      width: 0,
      dynamic: false,
      damPath: src,
      mediaQuery: rendition.queryType,
      height: 0,
    };
  });
};

// This function generates a list of image renditions for different breakpoints.
export const getRenditions = (src: string) => {
  const imageSizeList = [
    {
      breakpoint: Breakpoint?.Large,
      queryType: 'min-width',
      imageWidth: getContentBlockImageDimensions(DeviceSize?.Large),
    },
    {
      breakpoint: Breakpoint?.Medium,
      queryType: 'min-width',
      imageWidth: getContentBlockImageDimensions(DeviceSize?.Medium),
    },
    {
      breakpoint: Breakpoint?.Small,
      queryType: 'min-width',
      imageWidth: getContentBlockImageDimensions(DeviceSize?.Small),
    },
    {
      breakpoint: Breakpoint?.Small,
      queryType: 'max-width',
      imageWidth: getContentBlockImageDimensions(DeviceSize?.Small),
    },
  ];
  return generateImageRenditions(src, imageSizeList);
};
