// Imports for external libraries go here.
import clsx from 'clsx';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.

import { Button, Heading, Types, RichText } from '@marriott/mi-ui-library';
import { StyledStickyCardSection } from './StickyCardSection.styles';
import { StickyCardSectionProps } from './StickyCardSection.types';
import { CardArtBadge } from '../../../molecules/CardArtBadge';
import { CobrandImage } from '../../../molecules/CobrandImage/CobrandImage';

import { COLUMN_COUNT, VERTICAL } from '../../../modules/constants';
import { extractAnnualFee } from '../../../modules/utils';

export const StickyCardSectionConfig = {
  emptyLabel: 'StickyCardSection',
  isEmpty: () => true,
  resourceType: `/components/content/StickyCardSection`,
};

export const StickyCardSection = (props: StickyCardSectionProps) => {
  const {
    imagePath,
    isBadgeDetails,
    badgeIcon,
    badgeDetails,
    cardTitle,
    altText,
    linkText,
    linkUrl,
    openInaNewTab,
    additionalTrackingVariables,
    trackingDescription,
    impressionTrack,
    trackingContentPosition,
    atCCeVar48,
    clickTrack,
    merchandisingCategory,
    impressionCount,
    trackingTag,
    trackingOfferType,
    columnCount,
    hideButtonOnMobile,
    variant = 'horizontal',
    header,
    pointSectionDescription,
    tertiaryLinks,
    showCtaButton,
    isChaseButton,
    isAmexButton,
    isTertiaryLinksVisible,
    isImagehidden,
    isPointDescriptionVisible,
    isAnnualFeeVisible,
  } = props;

  const trackingProperties = {
    trackingContentPosition,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    merchandisingCategory,
    atCCeVar48,
    additionalTrackingVariables,
    clickTrack: clickTrack === 'true',
    impressionTrack: impressionTrack === 'true',
    impressionCount: impressionCount === 'true',
    isCoBrand: true,
  };
  const linkType = openInaNewTab ? 'external' : 'internal';
  const clickTrackValue = `${trackingContentPosition}|${trackingDescription}|${linkType}|${additionalTrackingVariables}`;

  const newHeader =
    variant && variant === 'vertical' ? cardTitle : cardTitle?.replace('t-subtitle-m', 't-label-alt-xs');

  const getCssClasses = (columnCount: string, variant: string) => {
    const baseClasses = 'd-flex card-art-badge-top sticky-card';
    const verticalClass = variant === VERTICAL ? 'd-flex flex-column align-items-center' : '';

    return `${baseClasses} ${
      columnCount === COLUMN_COUNT.COL_ONE
        ? 'justify-content-center mx-2'
        : columnCount === COLUMN_COUNT.COL_TWO
        ? 'mr-lg-5 pr-lg-4 mr-0'
        : ''
    } ${verticalClass}`;
  };

  return (
    <StyledStickyCardSection className={clsx('d-flex justify-content-center')}>
      <div
        className={clsx(
          columnCount === '1' ? 'justify-content-center mx-2' : columnCount === '2' ? 'mr-lg-5 pr-lg-4 mr-0' : '',
          'd-flex',
          'sticky-card',
          variant === 'vertical' ? 'd-flex flex-column align-items-center' : ''
        )}
      >
        {!isImagehidden &&
          (isBadgeDetails ? (
            <CardArtBadge
              imagePath={imagePath}
              imageClass="img-size"
              badgeClass="mt-4 mt-md-0 badge-width"
              badgeDetails={`<div class='d-flex align-items-center justify-content-center badge-txt-align'>
          <div><span class="${badgeIcon && `${badgeIcon} m-0 pr-1`}"></span></div>
          <div class='t-font-xs t-overline-overlay'>${badgeDetails?.toUpperCase()}</div>
        </div>`}
              customClass={clsx(getCssClasses(columnCount || '', VERTICAL))}
            />
          ) : (
            <CobrandImage
              defaultImageURL={imagePath}
              altText={altText}
              customClass={clsx('sticky-card-image', variant === VERTICAL && 'mt-md-1 sticky-card-image--large')}
            />
          ))}

        <div
          className={clsx(
            columnCount === '1'
              ? 'mx-lg-5 mx-md-3 mx-2'
              : columnCount === '2'
              ? 'mx-lg-4 ml-2 mr-md-3'
              : variant === 'vertical'
              ? 'mx-lg-1'
              : 'mx-lg-3 ml-2 mr-md-3',
            'd-flex flex-wrap',
            variant === 'vertical' ? 'justify-content-center text-center' : 'flex-column align-items-baseline'
          )}
        >
          {variant === 'vertical' && header && (
            <Heading
              titleText={header}
              variation={Types.headingType.title}
              fontSize={Types.size.medium}
              customClass={clsx('d-inline d-md-none text-center mt-4')}
            />
          )}

          <div
            dangerouslySetInnerHTML={{
              __html: isPointDescriptionVisible ? pointSectionDescription || '' : newHeader,
            }}
            className={clsx(
              variant === 'vertical'
                ? 'my-3 mb-md-2 mx-2 mx-lg-0 col-12'
                : (columnCount === '1' ? 'mb-2' : columnCount === '2' ? 'mb-2' : 'mb-md-3 mb-2') +
                    ' mt-0 mt-lg-2 sticky-card-heading' +
                    isPointDescriptionVisible && 'no-border-top-bottom flex-grow-1'
            )}
          />

          {isAnnualFeeVisible && (
            <RichText text={extractAnnualFee(header) || ''} componentId="annual-fee-message" customClass="pt-2" />
          )}

          {isTertiaryLinksVisible && (
            <div
              dangerouslySetInnerHTML={{ __html: tertiaryLinks || '' }}
              className={clsx(
                variant === 'vertical'
                  ? 'my-3 mb-md-2 mx-2 mx-lg-0 col-12'
                  : (columnCount === '1' ? 'mb-2' : columnCount === '2' ? 'mb-2' : 'mb-md-3 mb-2') +
                      'mt-0 mt-3 sticky-card-links'
              )}
            />
          )}
          {(isAmexButton || isChaseButton || showCtaButton) && (
            <div className={clsx(isAmexButton && 'mt-auto')}>
              <Button
                isLink
                href={linkUrl}
                trackingProperties={trackingProperties}
                target={openInaNewTab === 'true' ? '_blank' : '_self'}
                className={clsx(
                  'd-md-inline-block',
                  'track-completed',
                  isAmexButton ? 'mt-3 m-button-s' : 'd-none mt-auto m-button-m'
                )}
                linkType={linkType}
                custom_click_track_value={clickTrackValue}
                buttonCopy={linkText}
                linkAriaLabelOpenNewWindow={openInaNewTab ? 'Open in new tab' : ''}
              ></Button>
            </div>
          )}

          {(isChaseButton || showCtaButton) && (
            <Button
              isLink={true}
              href={linkUrl}
              trackingProperties={trackingProperties}
              target={openInaNewTab === 'true' ? '_blank' : '_self'}
              className={clsx(
                'm-link-tertiary-button-external m-link-action',
                hideButtonOnMobile ? 'd-none' : 'd-inline d-md-none',
                'pb-1 mb-1 mt-1 mt-auto mr-0',
                'track-completed'
              )}
              linkType={linkType}
              custom_click_track_value={clickTrackValue}
              buttonCopy={linkText}
              linkAriaLabelOpenNewWindow={openInaNewTab ? 'Open in new tab' : ''}
            ></Button>
          )}
        </div>
      </div>
    </StyledStickyCardSection>
  );
};
