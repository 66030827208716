// Styles for CardArtBadge go here.
import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';

export const StyledCardArtBadge = styled.div`
  .badge-border-style {
    border-radius: ${toRem(4)} ${toRem(4)} 0 0;
  }
  .img-full-width {
    width: -webkit-fill-available;
  }
`;
