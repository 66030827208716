import { tags, renditions } from '@marriott/mi-ui-library';

export enum ButtonStyle {
  PRIMARY_BUTTON = 'm-button-primary',
  PRIMARY_BUTTON_EXTERNAL = 'm-button-primary m-button-external',
  SECONDARY_BUTTON = 'm-button-secondary',
  SECONDARY_BUTTON_EXTERNAL = 'm-button-secondary m-button-external',
  TERTIARY_BUTTON = 'm-link-tertiary-button',
  TERTIARY_BUTTON_EXTERNAL = 'm-link-tertiary-button-external',
}

export interface TrackingPropsInterface {
  trackingContentPosition?: string;
  trackingOfferType?: string;
  atCCeVar48?: string;
  trackingDescription?: string;
  trackingTag?: string;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  impressionCount?: boolean;
  impressionEventType?: string;
  merchandisingCategory?: string;
  additionalTrackingVariables?: string;
  location?: string;
  payloadType?: string;
  compName?: string;
  enableScrollingBehavior?: boolean;
  isAuth?: boolean;
  clickTrackValSeparator?: string;
  cell?: string;
  spid?: string;
  rpcCode?: string;
  offerCode?: string;
  isDCAEnabled?: boolean;
  type?: string;
}

export interface SmallBannerProps {
  openInNewTab: boolean;
  destinationUrl1: string;
  callToActionText1: string;
  ctaType?: string;
  headerLong: string;
  descriptionLong?: string;
  descriptionLongMobile?: string;
  headerTag?: tags;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackingProperties?: any;
  image?: renditions;
  styleclass?: string;
  componentId?: string;
  openInNewWindowAriaLabel?: string;
  customDescriptionClass?: string;
  customContainerClass?: string;
  customImageClass?: string;
}

export interface ISmallbannerImage {
  logo: Record<string, string>;
  image: renditions;
}

interface IMetaData {
  campaignId: string;
  cell: string;
  countryCode: string;
  endDate: string;
  guidSecondary: string;
  issuerCode: string;
  loyaltyBar: boolean;
  mathOffer: boolean;
  merTag: string;
  offerCode: string;
  optionalFields: string;
  prequal: boolean;
  rpcCode: string;
  spid: string;
  startDate: string;
  theme: string;
  title: string;
  type: string;
}
export interface ITargetDataProps {
  isTargetContentLoaded?: boolean;
  isTargetContentError?: boolean;
  mathLabel1?: string;
  mathLabel2?: string;
  mathValue?: string;
  mathLabel3?: string;
  totalAfterCreditAmount?: string;
  mathTitle?: string;
  legalLong?: string;
  mathType?: string;
  mboxName?: string;
  metaData?: IMetaData;
}
export interface IMerchandisingSmallBannerImage extends ITargetDataProps {
  logo: {
    altText: string;
    imageSrc: string;
  };
  image: {
    altText: string;
    renditions: renditions[];
    dynamic: boolean;
    assetPath: string;
  };
  customImageClass?: string;
  isCobrand: boolean;
  isVariationsAvailable: boolean;
}

export interface IMerchandisingSmallBannerDescription extends ITargetDataProps {
  descriptionLong: string;
  descriptionLongMobile: string;
  customDescriptionClass: string;
}

export interface IMerchandisingSmallBannerButton {
  isAuth: boolean;
  ctaType: string;
  openInNewTab: boolean;
  destinationUrl1: string;
  callToActionText1: string;
  openInNewWindowAriaLabel: string;
  isVariationsAvailable: boolean;
  trackingProperties: {
    trackingContentPosition: string;
    trackingDescription: string;
    trackingOfferType: string;
    trackingTag: string;
    atCCeVar48: string;
    isClickTrack: boolean;
    impressionTrack: boolean;
    impressionCount: boolean;
    merchandisingCategory: string;
  };
  metaData?: IMetaData;
  targetData?: ITargetDataProps;
  isCobrand: boolean;
  isTargetContentError?: boolean;
  mboxParameter: string;
}

export interface IMerchandisingSmallBannerVariations
  extends Pick<ITargetDataProps, 'mathLabel1' | 'mathLabel2' | 'mathValue' | 'mathLabel3' | 'mathTitle'> {
  currencyType: string;
  currencyTotalStayAmount: number;
  pointsTotalStayAmount: number;
  isPointsVariation: boolean;
  isCurrencyVariation: boolean;
  isNightsVariation: boolean;
  currentEliteNightCredits: number;
}

export interface IMerchandisingSmallBannerContent extends ITargetDataProps {
  headerTag: tags;
  headerLong: string;
  isVariationsAvailable: boolean;
  descriptionProps: IMerchandisingSmallBannerDescription;
  variationProps: IMerchandisingSmallBannerVariations;
  buttonProps: IMerchandisingSmallBannerButton;
}

export interface IMerchandisingSmallBannerProps
  extends ITargetDataProps,
    IMerchandisingSmallBannerImage,
    IMerchandisingSmallBannerDescription,
    IMerchandisingSmallBannerButton,
    IMerchandisingSmallBannerVariations,
    IMerchandisingSmallBannerContent {
  mboxParameter: string;
  displayEvergreenContent: 'true' | 'false';
  customContainerClass: string;
  styleclass: string;
  componentId: string;
  colorScheme?: string;
  preview_prop_currency_type?: string;
  cellCodes?: string;
}
