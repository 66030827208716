import React from 'react';
import clsx from 'clsx';
import Image, { ImageLoaderProps } from 'next/image';
import { ImageProps, RenditionProps } from './CobrandImage.types';
import Head from 'next/head';

const customLoader = ({ src }: ImageLoaderProps): string => {
  return src;
};

export const CobrandImage = (props: ImageProps) => {
  const { title, defaultImageURL, customClass, renditions, altText = 'Card Overview Image', loading = 'lazy' } = props;

  return (
    <>
      {defaultImageURL && (
        <Head>
          <link rel="preload" as="image" href={defaultImageURL} />
        </Head>
      )}
      <picture data-component-name="m-cobrand-image" data-testid="cobrand-image">
        {renditions?.map((rendition: RenditionProps, index: number) => {
          const { renditionPath, mediaValue, mediaQuery } = rendition;
          const media = `(${mediaQuery}: ${mediaValue})`;
          return <source key={index} media={media} data-srcset={renditionPath} data-testid={'source-img-' + index} />;
        })}

        {defaultImageURL && (
          <Image
            loader={customLoader}
            src={defaultImageURL}
            className={clsx('image', loading, customClass)}
            alt={altText}
            title={title}
            data-src={defaultImageURL}
            loading={loading}
          />
        )}
      </picture>
    </>
  );
};
